<template>
  <CommonsModalsModalBody height="auto" :width="`${maxWidth ?? 950}px`" hide-close-icon>
    <template #content>
      <div class="document-viewer-container">
        <div class="dv-toolbar">
          <div v-if="isPdf" class="dv-pdf-pagination">
            <uds-icon
              icon-name="navigate_before"
              :color="pdfPage <= 1 ? udsColors.udsNeutral800 : udsColors.udsNeutral900"
              :disabled="pdfPage <= 1"
              style="cursor: pointer"
              @click="pdfPage <= 1 ? null : pdfPage--"
            />
            <span>{{ pdfPage }} / {{ pageCount }}</span>
            <uds-icon
              icon-name="navigate_next"
              :color="pdfPage >= pageCount ? udsColors.udsNeutral800 : udsColors.udsNeutral900"
              :disabled="pdfPage >= pageCount"
              style="cursor: pointer"
              @click="pdfPage >= pageCount ? null : pdfPage++"
            />
          </div>
          <uds-icon
            icon-name="rotate_right"
            :color="udsColors.udsNeutral800"
            style="cursor: pointer"
            size="medium"
            @click="rotate += 90"
          />
          <uds-icon
            icon-name="rotate_left"
            :color="udsColors.udsNeutral800"
            style="cursor: pointer"
            size="medium"
            @click="rotate -= 90"
          />
          <uds-icon
            icon-name="close"
            :color="udsColors.udsNeutral800"
            style="cursor: pointer"
            size="large"
            @click="$emit('cancel')"
          />
        </div>
        <div class="pdf-wrapper">
          <vue-pdf-embed
            v-if="isPdf"
            ref="pdfFullSizeRef"
            :source="fileUrl"
            :page="pdfPage"
            :rotation="rotate"
            @loaded="handleLoaded($event)"
          />
          <img
            v-else-if="isImage"
            :class="'rotation_' + rotate"
            :src="imageUrl"
            style="max-width: 100%"
          >
        </div>
      </div>
    </template>
  </CommonsModalsModalBody>
</template>

<script setup lang="ts">
import VuePdfEmbed from "vue-pdf-embed";

const props = defineProps<{
  maxWidth?: number;
  fileUrl: string | null;
  mimeType: string;
}>();

defineEmits(["cancel", "close"]);

const udsColors = getUdsColors();

const pageCount = ref(1);
const pdfPage = ref(1);

const pdfFullSizeRef = ref<any>(null);

const isPdf = computed(() => props.mimeType === "application/pdf");
const isImage = computed(() => ["image/jpeg", "image/jpg", "image/png"].includes(props.mimeType));

const imageUrl = computed(() => props.fileUrl ?? undefined);

function handleLoaded(event: any) {
  pageCount.value = event?._pdfInfo?.numPages ?? 1;
}

const rotate = ref(0);

watch(
  () => rotate.value,
  (value) => {
    if (value / 60 === 6 || value / 60 === -6) {
      rotate.value = 0;
    }
  },
);
</script>
<style lang="scss" scoped>
.document-viewer-container {
  .dv-toolbar {
    display: flex;
    gap: $uds-spacing-1;
    width: 100%;
    justify-content: flex-end;
  }

  .dv-pdf-pagination {
    display: flex;
    align-items: center;
    color: $uds-neutral-800;
  }
}

.rotation_90 {
  transform: rotate(90deg);
}

.rotation_180 {
  transform: rotate(180deg);
}

.rotation_270 {
  transform: rotate(270deg);
}

.rotation_-90 {
  transform: rotate(-90deg);
}

.rotation_-180 {
  transform: rotate(-180deg);
}

.rotation_-270 {
  transform: rotate(-270deg);
}
</style>
