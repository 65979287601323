<template>
  <div>
    <client-only>
      <div
        v-if="isPdf && pdfUrl"
        class="pdf-viewer-container"
        column
        wrap
        :style="`${maxHeight ? `max-height: ${maxHeight}px` : ''}; position:relative`"
      >
        <div
          v-if="hoverable"
          class="hovered-container"
          :class="{ hovered }"
          @mouseleave="hovered = false"
          @mouseover="hovered = true"
          @click="openFullSizeDocument()"
        >
          <vue-pdf-embed
            ref="pdfRef"
            :source="pdfUrl"
            :page="pdfPage"
            :rotation="rotate"
            @loaded="handleLoaded($event)"
            @error="$emit('error')"
          />
          <div v-if="hovered" class="hovered-content">
            <uds-icon icon-name="visibility" :color="udsColors.udsWhite" class="hovered-icon" />
            <p class="hovered-text">Afficher en plus grand</p>
          </div>
        </div>

        <vue-pdf-embed
          v-else
          ref="pdfRef"
          :key="`pdfref_${zoom}`"
          :source="pdfUrl"
          :page="pdfPage"
          :rotation="rotate"
          :style="`width: ${zoom * 100}%;`"
          @loaded="handleLoaded($event)"
          @loading-failed="handleLoadingFail"
        />
        <template v-if="!noPagination">
          <Teleport to="#pdfPaginationArrows">
            <div class="pdf-pagination">
              <uds-icon
                icon-name="navigate_before"
                :color="pdfPage <= 1 ? udsColors.udsNeutral800 : udsColors.udsNeutral900"
                :disabled="pdfPage <= 1"
                @click="pdfPage <= 1 ? null : pdfPage--"
              />
              <span>{{ pdfPage }} / {{ pageCount }}</span>
              <uds-icon
                icon-name="navigate_next"
                :color="pdfPage >= pageCount ? udsColors.udsNeutral800 : udsColors.udsNeutral900"
                :disabled="pdfPage >= pageCount"
                @click="pdfPage >= pageCount ? null : pdfPage++"
              />
            </div>
          </Teleport>
        </template>
      </div>
      <template v-if="!noDownload">
        <Teleport to="#pdfDownloadChip">
          <uds-tooltip tooltip-text="Télécharger">
            <uds-action-button
              icon-name="download"
              size="small"
              :disabled="!isDownloadAllowed"
              @click="downloadFile()"
            />
          </uds-tooltip>
        </Teleport>
      </template>
    </client-only>
  </div>
</template>

<script setup lang="ts">
import VuePdfEmbed from "vue-pdf-embed";
import { useModal } from "vue-final-modal";
import DocumentViewer from "@/components/commons/modals/DocumentViewer.vue";

const props = withDefaults(
  defineProps<{
    pdfUrl?: string | null;
    fileName?: string | null;
    page?: number;
    numPages?: number;
    rotate?: number;
    maxHeight?: number | null;
    mimeType?: string;
    hoverable?: boolean;
    zoom?: number;
    noPagination?: boolean;
    noDownload?: boolean;
  }>(),
  {
    pdfUrl: null,
    fileName: null,
    page: 1,
    numPages: 1,
    rotate: 0,
    maxHeight: 800,
    mimeType: "application/pdf",
    hoverable: false,
    zoom: 1,
    noPagination: false,
    noDownload: false,
  },
);
const emit = defineEmits(["loaded", "error"]);

const udsColors = getUdsColors();

const hovered = ref(false);
const isDownloadAllowed = ref(false);

const isPdf = computed<boolean>(() => {
  return !props.mimeType || props.mimeType === "application/pdf" || props.mimeType === "pdf";
});

watch(isPdf, () => {
  if (!isPdf.value) {
    emit("loaded");
  }
});

onMounted(() => {
  if (!isPdf.value) {
    emit("loaded");
  }
});

const pageCount = ref(1);
const pdfPage = ref(1);

const pdfRef = ref<any>(null);

function handleLoaded(event: any) {
  pageCount.value = event?._pdfInfo?.numPages ?? 1;
  isDownloadAllowed.value = true;
  emit("loaded");
}

function handleLoadingFail() {
  isDownloadAllowed.value = false;
  emit("error");
}

function downloadFile() {
  pdfRef.value?.download(props.fileName);
}

async function openFullSizeDocument() {
  const { open, close } = useModal({
    component: DocumentViewer,
    attrs: {
      fileUrl: props.pdfUrl,
      mimeType: props.mimeType,
      onClose() {
        close();
      },
      onCancel() {
        close();
      },
    },
  });
  await open();
}
</script>

<style lang="scss">
.scrollable {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: grab;
}

.scrollable:active {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: grabbing;
}

.hovered-container {
  position: absolute;
  width: 100%;

  &.hovered {
    position: absolute;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 109;
      cursor: pointer;
      background-color: rgba(33, 33, 33, 0.75);
    }
  }
  .hovered-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 110;
    color: $uds-white;
    font-weight: $uds-weight-700;
    font-size: 16px;
    cursor: pointer;
  }
}

.pdf-pagination {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  cursor: pointer;
  span {
    color: $uds-neutral-700;
    font-size: 14px;
  }
}
</style>
